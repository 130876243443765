<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card title="Board Info List">
          <b-card-text>
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th>Name</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Created At</th>
                  <th
                      class="text-center"
                      v-if="permission.edit || permission.delete"
                  >
                    Action
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(board, index) in boards" :key="index" v-if="boards.length">
                  <td class="align-middle text-center">
                    {{ 1 + index }}
                  </td>
                  <td><b></b>{{ board.name }}</td>
                  <td class="align-middle text-center">
                      <span
                          class="badge"
                          :class="
                          board.status == 1
                            ? 'badge-success'
                            : 'badge-danger'
                        "
                      >
                        {{ board.status == 1 ? "Active" : "Inactive" }}
                      </span>
                  </td>
                  <td class="text-center">{{board.created_at | date_time_format}}</td>
                  <td
                      class="align-middle text-center"
                      v-if="permission.edit || permission.delete"
                  >
                    <button
                        v-if="permission.edit"
                        class="btn btn-primary btn-sm"
                        @click="Edit(board.id)"
                    >
                      <feather-icon icon="EditIcon" size="16"/>
                    </button>

                    <button
                        v-if="permission.delete"
                        class="btn btn-danger btn-sm ml-1"
                        @click="Delete(board.id)"
                    >
                      <feather-icon icon="TrashIcon" size="16"/>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </b-card-text>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != null">
        <b-card :title="(!form.id ? 'Create' : 'Update') + ' Board Info'">
          <b-form @submit.prevent="!form.id ? Create() : Update(form.id)">
            <b-row class="justify-content-center">
              <b-col cols="12">
                <label> Name </label>
                <Input
                    v-model="form.name"
                    placeholder="Write Board Name"
                    style="width: 100%"
                    type="text"
                />
                <has-error :form="form" field="name"></has-error>
              </b-col>

              <b-col cols="12">
                <div
                    class="d-flex justify-content-between align-items-center mt-2"
                >
                  <b-form-checkbox
                      name="status"
                      style="margin-top: 5px"
                      v-model="form.status"
                      switch
                      inline
                  />
                  <div>
                    <b-button variant="danger" @click="Clear">
                      Clear
                    </b-button>
                    <b-button
                        variant="primary"
                        class="ml-1"
                        type="submit"
                        :disabled="form.busy"
                    >
                      {{ form.id ? "Update" : "Create" }}
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import {HasError, AlertError} from "vform/src/components/bootstrap5";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        status: true,
      }),
      boards: [],
    };
  },
  components: {
    BCard,
    BMedia,
    BFormFile,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BMediaBody,
    HasError,
    AlertError,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults() {
      axios
          .get("/app/education/board")
          .then((res) => {
            this.boards = res.data.boards;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    Clear() {
      this.form.id = null;
      this.form.name = null;
      this.form.status = true;
    },

    Create() {
      this.form
          .post("/app/education/board")
          .then((res) => {
            this.getResults();
            this.Clear();
            this.s(res.data.message);
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },
    Update(id) {
      this.form
          .put("/app/education/board/" + id)
          .then((res) => {
            this.getResults();
            this.Clear();
            this.s(res.data.message);
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    Edit(id) {
      axios
          .get("/app/education/board/" + id + "/edit")
          .then((res) => {
            this.form.id = res.data.board.id;
            this.form.name = res.data.board.name;
            this.form.status = res.data.board.status == 1 ? true : false;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
              .delete("/app/education/board/" + id)
              .then((res) => {
                this.s(res.data.message);
                this.getResults();
              })
              .catch((e) => {
                this.e(e.response.data.message);
              });
        }
      });
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.board;
    },
  },
};
</script>
